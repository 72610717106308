import {isResume} from "@/config/app";

export type Menu = { tag: string, title: string }

export default class AppFactory {
    public static menu(): Array<Menu> {
        if (isResume) {
            return [
                { tag: 'home', title: 'home' },
                { tag: 'about-me', title: 'about-me' },
                { tag: 'my-works', title: 'my-works' },
                { tag: 'contact', title: 'contact' }
            ]
        }
        return [
            { tag: 'home', title: 'home' },
            { tag: 'about-me', title: 'about-me' },
            { tag: 'my-works', title: 'my-works' },
            { tag: 'services', title: 'services' },
            { tag: 'contact', title: 'contact' }
        ]
    }
}
