<template>
  <p class="section-subtitle">
    <slot></slot>
  </p>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.section-subtitle {
  cursor: default;
}
</style>
