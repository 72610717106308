import IRepository from "@/contracts/IRepository";
import Skill from "@/models/Skill";
import Work from "@/models/Work";
import Language from "@/models/Language";
import Service from "@/models/Service";

const skills = [
    new Skill('C#', 92),
    new Skill('Asp Net Core', 90),
    new Skill('Type Script', 85),
    new Skill('Sql', 88),
]

const works = [
    new Work('ak-bars', '/img/logo/ak-bars.svg', 'rgb(17,58,43)', [
        '/img/works/ak-bars/1.jpg',
        '/img/works/ak-bars/2.jpg',
        '/img/works/ak-bars/3.jpg',
    ]),
    new Work('99-balls', '/img/logo/99ballov.svg', 'rgb(225,151,15)', [
        '/img/works/99-balls/1.jpg',
        '/img/works/99-balls/2.jpg',
        '/img/works/99-balls/3.jpg',
        '/img/works/99-balls/4.jpg',
        '/img/works/99-balls/5.jpg',
    ]),
    new Work('cred-it', '/img/logo/cred-it.png', 'rgb(65,143,137)'),
    new Work('kamastroyinvest', '/img/logo/kamastroyinvest.svg', 'rgb(187,66,4)', [
        '/img/works/kamastroyinvest/1.jpg',
        '/img/works/kamastroyinvest/2.jpg',
        '/img/works/kamastroyinvest/3.jpg',
        '/img/works/kamastroyinvest/4.jpg',
        '/img/works/kamastroyinvest/5.jpg',
        '/img/works/kamastroyinvest/6.jpg',
    ]),
    new Work('egida', '/img/logo/egida.png', 'rgb(252,252,252)', [
        '/img/works/egida/1.jpg',
        '/img/works/egida/2.jpg',
        '/img/works/egida/3.jpg',
    ]),
    new Work('lediana', '/img/logo/lediana.png', 'rgb(166,129,72)', [
        '/img/works/lediana/1.jpg',
        '/img/works/lediana/2.jpg',
        '/img/works/lediana/3.jpg',
        '/img/works/lediana/4.jpg',
    ]),
    new Work('energobank', '/img/logo/energobank.png', 'rgb(254,254,254)', [
        '/img/works/energobank/1.jpg',
        '/img/works/energobank/2.jpg',
        '/img/works/energobank/3.jpg',
    ]),
    new Work('tur-kazan', '/img/logo/tur-kazan.png', 'rgb(0,145,0)', [
        '/img/works/tur-kazan/1.jpg',
        '/img/works/tur-kazan/2.jpg',
        '/img/works/tur-kazan/3.jpg',
        '/img/works/tur-kazan/4.jpg',
    ]),
    new Work('kimi', '/img/logo/kimi.png', 'rgb(183,100,8)', [
        '/img/works/kimi/1.jpg',
        '/img/works/kimi/2.jpg',
        '/img/works/kimi/3.jpg',
        '/img/works/kimi/4.jpg',
    ]),
]

const languages = [
    new Language('EN', '/img/languages/us.svg'),
    new Language('RU', '/img/languages/ru.svg')
]

const services = [
    new Service('service-corporate', 'service-corporate-price', 'desktop-outline'),
    new Service('service-key', 'service-key-price', 'options-outline'),
    new Service('service-other', 'service-other-price', 'hardware-chip-outline'),
    new Service('service-support', 'service-support-price', 'construct-outline'),
]

export default class LocalRepository implements IRepository {
    getSkills(): Promise<Array<Skill>> {
        return Promise.resolve(skills);
    }

    getWorks(): Promise<Array<Work>> {
        return Promise.resolve(works);
    }

    getWorkByName(name: string): Promise<Work|null> {
        return Promise.resolve(works.find(item => item.name == name) || null);
    }

    getLanguages(): Array<Language> {
        return languages;
    }

    getServices(): Promise<Array<Service>> {
        return Promise.resolve(services);
    }
}
