import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import '@/assets/css/style.css'
import localization from '@/core/localization'
import { register } from 'swiper/element/bundle';

register();

createApp(App)
    .use(router)
    .use(localization)
    .mount('#app')