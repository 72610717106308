<template>
  <div class="footer">
    © {{ new Date().getFullYear() }} {{ $t('last-name') }} {{ $t('first-name') }}. {{ $t('footer-copyright') }}
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.footer {
  cursor: default;
  background-color: rgb(30,29,49);
  padding: 30px;
  font-size: 14px;
  text-align: center;
}
</style>
