<template>
  <section class="hero" id="home">
    <div class="container">

      <div class="hero-banner">

        <div style="width: 100%; height: 100%; overflow: hidden; padding: 15px 18px 0 7px; position: relative" class="photo">
          <img src="../assets/images/photo.png" loading="lazy" alt="Bariev Ilnar - Portfolio"
               style="width: 100%"
          >
        </div>

        <div class="elem elem-1">
          <p class="elem-title">3+</p>

          <p class="elem-text">{{ $t('experience-work') }}</p>
        </div>

        <div class="elem elem-2">
          <p class="elem-title">20+</p>

          <p class="elem-text">{{ $t('projects-completed') }}</p>
        </div>

        <div class="elem elem-3">
          <ion-icon name="trophy"></ion-icon>
        </div>

      </div>

      <div class="hero-content">

        <h2 class="hero-title">
          <span>{{ $t('welcome') }}</span>
          <strong>{{ $t('last-name') }} {{ $t('first-name') }}</strong> {{ $t('profession-from-country') }}
        </h2>

        <p class="hero-text">{{ $t('about-me-short-description') }}</p>

        <div class="btn-group">
          <a href="#contact" class="btn btn-primary blue">{{ $t('write-me') }}</a>

          <a href="#about-me" class="btn">{{ $t('about-me') }}</a>
        </div>

      </div>

    </div>
  </section>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.elem {
  cursor: default;
}

.hero-content {
  cursor: default;
}

.photo {
  border-radius: var(--radius-10);
  background-image: url("@/assets/images/hero-bg-phone.svg");
  background-blend-mode: darken;
}
</style>
