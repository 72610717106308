<template>
  <h2 class="h2 section-title">
    <slot></slot>
  </h2>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.section-title {
  cursor: default;
}
</style>
