<template>
  <section class="section">
    <div class="container">
      <slot></slot>
    </div>
  </section>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>

</style>
