<template>
  <p class="section-text">
    <slot></slot>
  </p>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.section-text {
  cursor: default;
}
</style>
