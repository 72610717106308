import { createI18n } from 'vue-i18n'

const messages = {
    en: {
        // Menu
        'home': 'Home',
        'about-me': 'About me',
        'my-works': 'My works',
        'services': 'Services',
        'contact': 'Contact',
        'download-cv': 'Download CV',

        // Personal Info
        'first-name': 'Ilnar',
        'last-name': '',
        'profession-from-country': 'Web Developer from Kazan',

        // Hero
        'welcome': 'Hello I\'m',
        'write-me': 'Write me',
        'projects-completed': 'Projects Completed',
        'experience-work': 'Experience Of Work',
        'about-me-short-description': 'I create high-quality and innovative web applications that will help you achieve your business goals.',

        // About Me
        'about-me-i-am-profession': 'I\'m a Developer',
        'about-me-quote': 'Programming Is My Passion',
        'about-me-description': 'I am a professional web developer with over 3 years of experience. My specialization is creating high-quality web applications that can satisfy the needs of any client.<br/><br/>I strive to constantly develop my skills and keep up with new trends in web development to offer my clients the most modern solutions.<br/><br/>I am passionate about programming and believe that my work is not just a profession, but also a form of creativity that helps me bring my ideas to life and make the dreams of my clients a reality.',
        'about-me-view-portfolio': 'View Portfolio',

        // My Skills
        'my-skills-title': 'My Skills',
        'my-skills-quote': 'I Develop Skills Regularly',
        'my-skills-description': 'I possess a wide range of skills, including knowledge of programming languages such as JavaScript, PHP, C#, and others.<br />Additionally, I have experience working with various frameworks and libraries.',

        // My Works
        'my-works-title': 'Portfolio',
        'my-works-quote': 'My Works',
        'my-works-description': 'On this website, you can see examples of my projects in which I have been involved. Each project describes my tasks and work results.',

        // Contact
        'contact-title': 'Don\'t Be Shy',
        'contact-quote': 'Drop Me a Line',
        'contact-description': 'If you have an interesting project that requires an experienced web developer, I am ready to help you. Please contact me through the contact form on my website, and we will discuss the details of your project.',
        'contact-form-name': 'Name',
        'contact-form-email': 'Email',
        'contact-form-text': 'Message',
        'contact-form-send': 'Send',
        'contact-social-email': 'Email',
        'contact-social-telegram': 'Telegram',
        'contact-success': 'Thank you for submitting the form!<br/>Your message has been successfully received.',
        'contact-error': 'We apologize, but unfortunately, your form was not submitted.',

        // Services
        'services-title': 'My Services',
        'services-quote': 'What I do',
        'services-description': `I provide high-quality web development services that are tailored to the needs of my clients. My service includes creating corporate websites, turnkey websites, as well as support and maintenance of websites throughout their entire lifecycle. I guarantee high-quality work and an individual approach to each project.<br/><br/>Additionally you will receive
        <ul style="margin-left: 36px;">
          <li style="list-style: circle">Production and test environment</li>
          <li style="list-style: circle">Backup</li>
          <li style="list-style: circle">Logging</li>
          <li style="list-style: circle">3 months warranty</li>
        </ul>`,
        'service-corporate': 'Corporate website',
        'service-corporate-price': '2000$',
        'service-key': 'Turnkey website',
        'service-key-price': '3500$',
        'service-other': 'Other',
        'service-other-price': '1000$',
        'service-support': 'Support and maintenance',
        'service-support-price': '800$',

        // Footer
        'footer-copyright': 'All rights reserved.',

        // Works
        'portfolio.ak-bars.name': 'Ak Bars',
        'portfolio.ak-bars.quote': 'Ticket system',
        'portfolio.ak-bars.description': 'Ice Palace of Sports \'Tatneft Arena\' is a Russian multifunctional sports and concert complex located in the city of Kazan. It is one of the largest and well-equipped ice sports palaces in Russia and Europe.',

        'portfolio.99-balls.name': '99 Balls',
        'portfolio.99-balls.quote': 'Online education',
        'portfolio.99-balls.description': '99 Points is an educational online platform with automatic individual adaptation of micro-learning processes and a personalized recommendation system.<br/><br/>The platform provides preparation for the Unified State Exam (USE) and Basic State Exam (BSE), as well as for entry-level professions. This platform helps high school students not only prepare for final exams but also obtain objective data about themselves, choose a specialty that best suits their abilities, and acquire their first respectable profession on a single educational platform.',

        'portfolio.cred-it.name': 'Cred IT',
        'portfolio.cred-it.quote': 'Credit system',
        'portfolio.cred-it.description': 'Cred-IT is a market expert in the field of lending, insurance, and other financial services. The organization is one of the active credit brokers in the POS lending segment.',

        'portfolio.kamastroyinvest.name': 'KamaStroyInvest',
        'portfolio.kamastroyinvest.quote': 'Sale of housing',
        'portfolio.kamastroyinvest.description': 'KamaStroyInvest is a construction and investment company founded in 2006. It specializes in the construction of unique residential and commercial real estate projects in Kazan.',

        'portfolio.egida.name': 'Egida',
        'portfolio.egida.quote': 'Wholesale manufacturer',
        'portfolio.egida.description': 'Egida is one of the market leaders in the production of polyurethane foam, fabrics, adhesives, and components for upholstered furniture.',

        'portfolio.lediana.name': 'Lediana',
        'portfolio.lediana.quote': 'Photobook service',
        'portfolio.lediana.description': 'A convenient service for printing stylish photo books.',

        'portfolio.energobank.name': 'Energobank',
        'portfolio.energobank.quote': 'Financial sector',
        'portfolio.energobank.description': 'Energobank is one of the oldest banks in Russia, founded in March 1989.<br/><br/>For 34 years, they have been providing banking services to individuals, businesses, and organizations. They are among the top 200 banks in Russia in terms of volume of loans and deposits from individuals.<br/><br/>Their offices operate in the Republic of Tatarstan, Ulyanovsk and Samara regions, and the Republic of Chuvashia.',

        'portfolio.tur-kazan.name': 'Tur Kazan',
        'portfolio.tur-kazan.quote': 'Tour agency',
        'portfolio.tur-kazan.description': 'At present, \'Excursion Service Kazan\' is among the leading tour operators specializing in organizing sightseeing and educational leisure activities for residents of Kazan and visitors to our beautiful city.<br/><br/>\'Excursion Service Kazan\' is the primary contractor for hotels in organizing tour services throughout Kazan and the Republic of Tatarstan. In any hotel in the city, you will be provided with additional services related to excursion organization.',

        'portfolio.kimi.name': 'KIMI',
        'portfolio.kimi.quote': 'Repair services',
        'portfolio.kimi.description': 'One of the largest housing management companies in the Moscow region, serving subscribers in 6 cities of the Moscow suburbs. The company has been providing utility services for over 20 years.',

        // Validation
        'validation-required': 'The \'{attribute}\' field is required.',
        'validation-email': 'The \'{attribute must}\' be a valid email address.'

    },
    ru: {
        // Menu
        'home': 'Главная',
        'about-me': 'Обо мне',
        'my-works': 'Мои работы',
        'services': 'Услуги',
        'contact': 'Контакт',
        'download-cv': 'Скачать резюме',

        // Personal Info
        'first-name': 'Ильнар',
        'last-name': '',
        'profession-from-country': 'Веб разработчик из Казани',

        // Hero
        'welcome': 'Привет. Меня зовут',
        'write-me': 'Напиши мне',
        'projects-completed': 'Выполненные проекты',
        'experience-work': 'Опыт работы',
        'about-me-short-description': 'Я создаю качественные и инновационные веб-приложения, которые помогут вам достичь ваших бизнес-целей.',

        // About Me
        'about-me-i-am-profession': 'Я являюсь разработчиком',
        'about-me-quote': 'Программирование - Моя Страсть',
        'about-me-description': `Я профессиональный веб-разработчик с опытом работы более 3 лет. Моя специализация - создание высококачественных веб-приложений, которые могут удовлетворить потребности любых клиентов.<br/><br/>Я стараюсь постоянно развивать свои навыки и следить за новыми тенденциями в веб-разработке, чтобы предлагать своим клиентам самые современные решения.<br/><br/>Я увлекаюсь программированием и считаю, что моя работа — это не просто профессия, но и творчество, которое помогает мне реализовывать свои идеи и воплощать в жизнь мечты моих клиентов.`,
        'about-me-view-portfolio': 'Посмотреть портфолио',

        // My Skills
        'my-skills-title': 'Мои навыки',
        'my-skills-quote': 'Я регулярно развиваю навыки',
        'my-skills-description': 'Я владею широким спектром навыков, включая знание языков программирования JavaScript, PHP, C# и других.<br />Я также имею опыт работы с различными фреймворками и библиотеками.',

        // My Works
        'my-works-title': 'Портфолио',
        'my-works-quote': 'Мои Работы',
        'my-works-description': 'На этом сайте вы можете увидеть примеры моих проектов, в которых я принимал участие.<br /> Каждый проект описывает мои задачи и результаты работы.',

        // Contact
        'contact-title': 'Не Стесняйся',
        'contact-quote': 'Напишите Мне',
        'contact-description': 'Если у вас есть интересный проект, который требует опытного веб-разработчика, то я готов помочь вам. Свяжитесь со мной через форму обратной связи на моем сайте, и мы обсудим детали вашего проекта.',
        'contact-form-name': 'Имя',
        'contact-form-email': 'Электронная почта',
        'contact-form-text': 'Сообщение',
        'contact-form-send': 'Отправить',
        'contact-social-email': 'Электронная почта',
        'contact-social-telegram': 'Телеграм',
        'contact-success': 'Благодарим за отправку формы!<br/>Ваше сообщение было успешно получено.',
        'contact-error': 'Приносим извинения, но к сожалению, ваша форма не была отправлена.',

        // Services
        'services-title': 'Мои услуги',
        'services-quote': 'Чем я занимаюсь',
        'services-description': `Я предоставляю качественные услуги веб-разработки, которые ориентированы на потребности моих клиентов. Мой сервис включает создание корпоративных сайтов, сайтов под ключ, а также поддержку и сопровождение сайтов на протяжении всего их жизненного цикла. Я гарантирую высокое качество работы и индивидуальный подход к каждому проекту.<br/><br/>Дополнительно вы получите:
        <ul style="margin-left: 36px;">
          <li style="list-style: circle">Тестовая и боевая среда</li>
          <li style="list-style: circle">Резервное копирование</li>
          <li style="list-style: circle">Логирование</li>
          <li style="list-style: circle">3 месяца гарантии</li>
        </ul>`,
        'service-corporate': 'Корпоративный сайт',
        'service-corporate-price': '120000₽',
        'service-key': 'Сайт "Под ключ"',
        'service-key-price': '250000₽',
        'service-other': 'Прочие',
        'service-other-price': '80000₽',
        'service-support': 'Поддержка и сопровождение',
        'service-support-price': '50000₽',

        // Footer
        'footer-copyright': 'Все права защищены.',

        // Works
        'portfolio.ak-bars.name': 'Ак Барс',
        'portfolio.ak-bars.quote': 'Билетная система',
        'portfolio.ak-bars.description': 'Ледо́вый дворе́ц спо́рта «Татне́фть Аре́на» — российский многофункциональный спортивно-концертный комплекс в городе Казань. Один из самых больших и хорошо оснащённых в России и Европе ледовых дворцов спорта.',

        'portfolio.99-balls.name': '99 Баллов',
        'portfolio.99-balls.quote': 'Онлайн образование',
        'portfolio.99-balls.description': '99 баллов - образовательная онлайн-платформа с автоматической индивидуальной адаптацией micro-learning процессов и системой персональных рекомендаций.<br/><br/>Платформа обеспечивает подготовку к ЕГЭ/ОГЭ и первым профессиям. Данная платформа помогает ученикам старших классов не только подготовиться к выпускным экзаменам, но и получить объективные данные о себе, выбрать максимально подходящую под способности ученика специальность и получить первую достойную профессию на одной образовательной платформе.',

        'portfolio.cred-it.name': 'Cred IT',
        'portfolio.cred-it.quote': 'Кредитная система',
        'portfolio.cred-it.description': 'Cred-IT — это эксперт рынка в области кредитования, страхования и других финансовых услуг. Организация является одним из активных кредитных Брокеров в сегменте POS кредитования.',

        'portfolio.kamastroyinvest.name': 'КамаСтройИнвест',
        'portfolio.kamastroyinvest.quote': 'Продажа жилья',
        'portfolio.kamastroyinvest.description': 'Компания КамаСтройИнвест — строительно-инвестиционная компания, основана в 2006 году, специализируется на строительстве уникальных объектов жилой и коммерческой недвижимости в Казани.',

        'portfolio.egida.name': 'Эгида',
        'portfolio.egida.quote': 'Оптовый производитель',
        'portfolio.egida.description': 'Эгида - Одни из лидеров рынка по производству пенополиуретана, тканей, клея и комплектующих для мягкой мебели.',

        'portfolio.lediana.name': 'Lediana',
        'portfolio.lediana.quote': 'Сервис фотокниг',
        'portfolio.lediana.description': 'Удобный сервис по печати стильных фотокниг.',

        'portfolio.energobank.name': 'Энегробанк',
        'portfolio.energobank.quote': 'Финансовый сектор',
        'portfolio.energobank.description': 'Энергобанк – один из старейших банков России – основан в марте 1989 года.<br/><br/>34 года они оказываем банковские услуги населению, бизнесу и организациям. Входим в ТОП-200 Банков России по объему кредитов и депозитов физических лиц.<br/><br/>Их офисы работают в Республике Татарстан, Ульяновской и Самарской области и Республике Чувашия',

        'portfolio.tur-kazan.name': 'Тур Казань',
        'portfolio.tur-kazan.quote': 'Экскурсионное агентство',
        'portfolio.tur-kazan.description': 'На сегодняшний день «Экскурсионный Сервис Казань» входит в число лидирующих туроператоров по организации экскурсионного и культурно-познавательного отдыха для казанцев и гостей нашего прекрасного города.<br/><br/>«Экскурсионный Сервис Казань» является основным подрядчиком гостиниц в организации экскурсионного обслуживания по Казани и Республике Татарстан. В любом отеле города вам предоставят дополнительный сервис, связанный с организацией экскурсий.',

        'portfolio.kimi.name': 'KIMI',
        'portfolio.kimi.quote': 'Ремонтные услуги',
        'portfolio.kimi.description': 'Одна из крупнейших УК Московской области, обслуживающая абонентов сразу в 6 городах Подмосковья. Компания осуществляет услуги в сфере коммунального обслуживания более 20 лет.',

        // Validation
        'validation-required': 'Поле \'{attribute}\' обязательно для заполнения.',
        'validation-email': 'Поле \'{attribute}\' должно быть действительным электронным адресом.'

    }
}

let locale = localStorage.getItem('locale')
if (!locale) {
    locale = navigator.language.split('-')[0]
}

export default createI18n({
    locale: locale,
    fallbackLocale: 'ru',
    messages
})
