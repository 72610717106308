import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import DetailWorkView from '@/views/DetailWorkView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/works/:id',
    name: 'works.detail',
    component: DetailWorkView
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return ({
        el: to.hash,
        behavior: 'smooth',
      })
    } else if (savedPosition) {
      return (savedPosition);
    } else {
      return {left: 0, top: 0}
    }
  }
})

export default router
