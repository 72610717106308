<template>
  <router-link :to="{ name: 'home' }">
    <h1 class="logo">{{ $t('last-name') }} {{ $t('first-name') }}</h1>
  </router-link>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>

</style>
