<template>
  <ul class="blog-list">
    <slot></slot>
  </ul>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>

</style>
